import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Category} from "../../interfaces/category";
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";
import {UtilsService} from "../../services/utils.service";
import {Picture} from "../../interfaces/picture";

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {
  mode: string;
  picture: Picture;
  pictures: Picture[];
  categories: Category[];
  category: Category;
  pictureFile: string;
  additionalPictureFile: string;
  sections = [];
  techniques = [];
  series = [];
  styles = [];
  movements = [];
  existedPicture: Picture;
  existedPictureError: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false
  };

  constructor(public router: Router,
              private storage: StorageService,
              public rest: RESTService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.picture = {id: 0};
    this.existedPicture = {id: 0};
    this.mode = 'list';
    setTimeout(() => {
      this.readCategories();
      this.readTechniques();
      this.readSeries();
      this.readSections();
      this.readStyles();
      this.readMovements();
    });
  }

  readCategories() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readCategories(token).subscribe((data) => {
      this.categories = data;
      this.category = this.categories[0];
      this.storage.set('loading', false);
      this.readPictures();
    });
  }

  readSections() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readSections(token).subscribe((data) => {
      this.sections = data;
      this.storage.set('loading', false);
    });
  }

  readTechniques() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readTechniques(token).subscribe((data) => {
      this.techniques = data;
      this.storage.set('loading', false);
    });
  }

  readSeries() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readSeries(token).subscribe((data) => {
      this.series = data;
      this.storage.set('loading', false);
    });
  }

  readStyles() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readStyles(token).subscribe((data) => {
      this.styles = data;
      this.storage.set('loading', false);
    });
  }

  readMovements() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readMovements(token).subscribe((data) => {
      this.movements = data;
      this.storage.set('loading', false);
    });
  }

  readPictures() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readPictures(token, this.category).subscribe((data) => {
      this.pictures = data;
      this.storage.set('loading', false);
    });
  }

  add(picture: Picture) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    if (picture['date'] && picture['date'] !== '0000-00-00') {
      picture['date'] = new Date(picture['date']).toISOString().substring(0, 10);
    } else {
      picture['date'] = '0000-00-00';
    }
    this.rest.createPicture(token, picture).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
    });
  }

  edit(picture: Picture) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    if (picture['date'] && picture['date'] !== '0000-00-00') {
      picture['date'] = new Date(picture['date']).toISOString().substring(0, 10);
    } else {
      picture['date'] = '0000-00-00';
    }
    this.rest.updatePicture(token, picture).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
    });
  }

  delete(picture: Picture) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.deletePicture(token, picture).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
      this.readPictures();
    });
  }

  open(picture: Picture) {
    this.existedPictureError = {};
    this.existedPicture = {id: 0};
    this.picture = picture;
    this.picture['category'] = this.picture['categoryId'];
    this.picture['section'] = this.picture['sectionId'];
    this.picture['style'] = this.picture['styleId'];
    this.picture['movement'] = this.picture['movementId'];
    this.mode = 'picture';
  }

  cancel() {
    this.mode = 'list';
    this.picture = {id: 0};
  }

  chooseFile(fileType: string) {
    const fileInputName = (fileType === 'picture') ? 'picture-file-input' : 'additionalImage-file-input';
    document.getElementById(fileInputName).click();

    if (fileType === 'picture') {
      this.additionalPictureFile = (<any>document.getElementById(fileInputName)).value;
    } else {
      this.pictureFile = (<any>document.getElementById(fileInputName)).value;
    }
  }

  uploadFile(fileType: string) {
    const fileInputName = (fileType === 'picture') ? 'picture-file-input' : 'additionalImage-file-input';
    const fileInput = (<any>document.getElementById(fileInputName));
    const file = fileInput.files ? fileInput.files[0] : '';
    this.storage.set('loading', true);
    const token = this.storage.get('token');

    const formData = new FormData();

    let categoryUrl = '';

    for (let c of this.categories) {
      if (this.picture['category'] === c.id) {
        categoryUrl = c.url;
      }
    }

    if (fileType === 'picture') {
      formData.append('filename', categoryUrl + '_' + this.picture['url']);
    } else {
      const additionalImageIndex = this.picture['additionalImages'] ? (this.picture['additionalImages'].length + 1) : 1;
      formData.append('filename', categoryUrl + '_' + this.picture['url'] + '_additional_' + additionalImageIndex);
    }
    formData.append('filetype', fileType);
    formData.append('file', file, file.name);
    this.rest.uploadFile(token, formData).subscribe((data) => {
      if (fileType === 'picture') {
        this.picture['picture'] = data['file'];
      } else {
        if (!this.picture['additionalImages']) {
          this.picture['additionalImages'] = [];
        }
        this.picture['additionalImages'].push(data['file']);
      }
      this.storage.set('loading', false);
    });
  }

  deleteFile(fileType: string, fileName: string) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');

    function clearDeletedPictureValue(picture) {
      if (fileType === 'picture') {
        picture['picture'] = '';
      } else {
        const index = picture['additionalImages'].indexOf(fileName);
        if (index !== -1) picture['additionalImages'].splice(index, 1);
      }
    }

    this.rest.deleteFile(token, fileName).subscribe((data) => {
      clearDeletedPictureValue(this.picture);
    }, error => {
      if (error.status === 404) {
        clearDeletedPictureValue(this.picture);
      }
    });
  }

  doesPictureExist() {
    if (this.picture.category) {
      this.storage.set('loading', true);
      const token = this.storage.get('token');
      this.existedPictureError = {sameTitle: false, sameTitleEn: false, sameCategory: false, sameUrl: false};
      this.rest.searchExistedPicture(token, this.picture.category, this.picture.title, this.picture.titleEn, this.picture.url).subscribe((data) => {
        if (data['id'] && data['id'] !== this.picture['id']) {
          this.existedPicture = data;
          if (this.existedPicture['title'].toLowerCase() === this.picture['title'].toLowerCase()) {
            this.existedPictureError.sameTitle = true;
          }
          if (this.existedPicture['titleEn'].toLowerCase() === this.picture['titleEn'].toLowerCase()) {
            this.existedPictureError.sameTitleEn = true;
          }
          if (this.existedPicture['category'] === this.picture['categoryId']) {
            this.existedPictureError.sameCategory = true;
          }
          if (this.existedPicture['url'].toLowerCase() === this.picture['url'].toLowerCase()) {
            this.existedPictureError.sameUrl = true;
          }
        } else {
          this.existedPicture = {id: 0};
        }
        this.storage.set('loading', false);
      });
    }
  }

  onTitleChanged() {
    if (!this.picture['id']) {
      this.picture['titleEn'] = this.utils.transliterate(this.picture['title']);
      this.onTitleEnChanged();
    }
  }

  onTitleEnChanged() {
    this.onCustomUrlChange();
  }

  onCustomUrlChange() {
    if (!this.picture['customUrl']) {
      this.picture['url'] = this.utils.stringToURL(this.picture['titleEn']);
    }
  }
}
