import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";

@Component({
  selector: 'app-about',
  templateUrl: './contacts-sales.component.html',
  styleUrls: ['./contacts-sales.component.scss']
})
export class ContactsSalesComponent implements OnInit {
  info: string;
  infoEn: string;
  oldInfo: string;
  oldInfoEn: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false
  };

  constructor(public router: Router,
              private storage: StorageService,
              public rest: RESTService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.read();
    });
  }

  cancel() {
    this.info = this.oldInfo;
    this.infoEn = this.oldInfoEn;
  }

  read() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readContactsSales(token).subscribe((data) => {
      this.info = data.info;
      this.infoEn = data.infoEn;
      this.oldInfo = this.info;
      this.oldInfoEn = this.infoEn;
      this.storage.set('loading', false);
    });
  }

  update() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.updateContactsSales(token, this.info, this.infoEn).subscribe((data) => {
      this.storage.set('loading', false);
      this.router.navigate(['/home']);
    });
  }
}
