<mat-card>
  <mat-card-header>
    <h2>Редагування контактів і продажу</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-label>Опис</mat-label>
    <angular-editor [placeholder]="'Опис'" [(ngModel)]="info" [config]="editorConfig"></angular-editor>
    <mat-label>Опис Англійською</mat-label>
    <angular-editor [placeholder]="'Опис англійською'" [(ngModel)]="infoEn" [config]="editorConfig"></angular-editor>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="update()">Зберегти</button>
    <button mat-button (click)="cancel()">Відміна</button>
  </mat-card-actions>
</mat-card>
