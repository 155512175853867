import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(public router: Router,
              private storage: StorageService,
              private rest: RESTService) {
  }

  ngOnInit() {
  }

  login() {
    this.storage.set('loading', true);
    this.rest.login(this.email, this.password).subscribe((data) => {
      this.storage.set('token', data.token)
      this.storage.set('loading', false);
      this.router.navigate(['/home']);
    });
  }
}
