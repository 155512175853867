import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  home: string;
  homeEn: string;
  oldHome: string;
  oldHomeEn: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false
  };

  constructor(public router: Router,
              private storage: StorageService,
              private rest: RESTService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.read();
    });
  }

  cancel() {
    this.home = this.oldHome;
    this.homeEn = this.oldHomeEn;
  }

  read() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readHome(token).subscribe((data) => {
      this.home = data.home;
      this.homeEn = data.homeEn;
      this.oldHome = this.home;
      this.oldHomeEn = this.homeEn;
      this.storage.set('loading', false);
    });
  }

  update() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.updateHome(token, this.home, this.homeEn).subscribe((data) => {
      this.storage.set('loading', false);
      this.router.navigate(['/home']);
    });
  }
}
