import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  private data: any;

  public set(key: string, data: any) {
    this.data[key] = data;
  }

  public get(key: string) {
    return this.data[key];
  }

  public constructor() {
    this.data = {};
  }
}
