<mat-drawer-container class="menu-container">
  <mat-drawer mode="side" opened *ngIf="storage.get('token')">
    <button mat-button routerLink="/home" routerLinkActive="active-menu-item">Головна</button>
    <button mat-button routerLink="/pictures" routerLinkActive="active-menu-item">Галерея</button>
    <button mat-button routerLink="/category" routerLinkActive="active-menu-item">Категорії</button>
    <button mat-button routerLink="/about" routerLinkActive="active-menu-item">Про художника</button>
    <button mat-button routerLink="/media" routerLinkActive="active-menu-item">Публікації</button>
    <button mat-button routerLink="/contacts-sales" routerLinkActive="active-menu-item">Контакти і продаж робіт</button>
    <button mat-button routerLink="/shop" routerLinkActive="active-menu-item">Магазин</button>
  </mat-drawer>
  <mat-drawer-content class="page-container">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading()"></mat-progress-bar>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
