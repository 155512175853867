import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AboutComponent} from './components/about/about.component';
import {ShopComponent} from './components/shop/shop.component';
import {ContactsSalesComponent} from './components/contacts-sales/contacts-sales.component';
import {CategoryComponent} from './components/category/category.component';
import {PicturesComponent} from './components/pictures/pictures.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilesComponent} from './components/files/files.component';
import {MediaComponent} from './components/media/media.component';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/home/home.component';
import {MaterialModule} from './material-module';
import {StorageService} from './services/storage.service';
import {RESTService} from './services/rest.service';
import {UtilsService} from './services/utils.service';
import {MatInputModule} from "@angular/material/input";
import {AngularEditorModule} from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ShopComponent,
    CategoryComponent,
    ContactsSalesComponent,
    FilesComponent,
    MediaComponent,
    LoginComponent,
    HomeComponent,
    PicturesComponent,
  ],
  imports: [
    BrowserModule,
    AngularEditorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [StorageService, RESTService, UtilsService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
