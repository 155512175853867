import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";
import {Media} from "../../interfaces/media";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-medias',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})

export class MediaComponent implements OnInit {
  mode: string;
  media: Media[];
  mediaItem: Media;
  existedMedia: Media;
  existedMediaError: any;
  searchexistedMediaTimeout: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false
  };

  constructor(public router: Router,
              private storage: StorageService,
              public rest: RESTService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    this.mode = 'list';
    setTimeout(() => {
      this.readMedia();
    });
  }

  readMedia() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readMedia(token).subscribe((data) => {
      this.media = data;
      this.storage.set('loading', false);
    });
  }

  add(media: Media) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.createMedia(token, media).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
    });
  }

  edit(media: Media) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.updateMedia(token, media).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
    });
  }

  delete(media: Media) {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.deleteMedia(token, media).subscribe((data) => {
      this.mode = 'list';
      this.storage.set('loading', false);
      this.readMedia();
    });
  }

  open(media: Media) {
    this.mediaItem = media;
    this.mode = 'media';
    this.existedMediaError = {};
    this.existedMedia = {id: 0};
  }

  searchExistedMedia() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.existedMediaError = {
      sameTitle: false, sameTitleEn: false, sameLocation: false, sameYear: false, sameUrl: false
    };
    clearTimeout(this.searchexistedMediaTimeout);
    this.searchexistedMediaTimeout = setTimeout(() => {
      this.rest.searchExistedMedia(token, this.mediaItem.title, this.mediaItem.titleEn, this.mediaItem.url).subscribe((data) => {
        if (data['id'] && data['id'] !== this.mediaItem['id']) {
          this.existedMedia = data;
          if (this.existedMedia['title'].toLowerCase() === this.mediaItem['title'].toLowerCase()) {
            this.existedMediaError.sameTitle = true;
          }
          if (this.existedMedia['titleEn'].toLowerCase() === this.mediaItem['titleEn'].toLowerCase()) {
            this.existedMediaError.sameTitleEn = true;
          }
          if (this.existedMedia['url'].toLowerCase() === this.mediaItem['url'].toLowerCase()) {
            this.existedMediaError.sameUrl = true;
          }
        } else {
          this.existedMedia = {id: 0};
        }
        this.storage.set('loading', false);
      });
    }, 500);
  }

  cancel() {
    this.mode = 'list';
    this.mediaItem = {};
  }

  onTitleChanged() {
    if (!this.mediaItem['id']) {
      this.mediaItem['titleEn'] = this.utils.transliterate(this.mediaItem['title']);
      this.onTitleEnChanged();
    }
    this.searchExistedMedia();
  }

  onTitleEnChanged() {
    this.onCustomUrlChange();
    this.searchExistedMedia();
  }

  onCustomUrlChange() {
    if (!this.mediaItem['customUrl']) {
      this.mediaItem['url'] = this.utils.stringToURL(this.mediaItem['titleEn']);
    }
    this.searchExistedMedia();
  }
}
