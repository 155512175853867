<mat-card>
  <mat-card-header>
    <h2>Галерея робіт</h2>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <mat-label>Категорія</mat-label>
      <mat-select [(ngModel)]="category" (ngModelChange)="readPictures()">
        <mat-option *ngFor="let c of categories" [value]="c">
          {{c.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ul cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <li class="example-box" *ngFor="let item of pictures" cdkDrag>{{item.title}}</li>
    </ul>
    <button mat-button (click)="cancel()">Відміна</button>
    <button mat-button color="primary" (click)="save()">Зберегти зміни</button>
  </mat-card-content>
</mat-card>
