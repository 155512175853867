import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Category} from "../interfaces/category";
import {Media} from "../interfaces/media";
import {Picture} from "../interfaces/picture";

@Injectable()
export class RESTService {
  constructor(private http: HttpClient) {
  }

  host = 'https://api.shupliak.art';
  websiteHost = 'https://shupliak.art';

  login(
    email: string,
    password: string) {
    const params = new HttpParams().set('email', email).set('password', password);
    return this.http.get<any>(this.host + '/login',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    );
  }

  readCategories(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/categories',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readSections(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/sections',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readTechniques(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/techniques',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readSeries(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/series',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readStyles(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/styles',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readMovements(
    token: string) {
    const params = new HttpParams();
    return this.http.get<any[]>(this.host + '/movements',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  readPictures(
    token: string,
    category: Category) {
    const params = new HttpParams().set('category', category.id.toString());
    return this.http.get<Picture[]>(this.host + '/pictures',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  createPicture(
    token: string,
    picture) {
    return this.http.post<any>(this.host + '/picture',
      picture,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updatePicture(
    token: string,
    picture) {
    return this.http.put(this.host + '/picture',
      picture,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  deletePicture(
    token: string,
    picture) {
    const params = new HttpParams().set('id', picture.id);
    return this.http.delete(this.host + '/picture',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  readMedia(
    token: string) {
    const params = new HttpParams();
    return this.http.get<Media[]>(this.host + '/media',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  createMedia(
    token: string,
    media) {
    return this.http.post<any>(this.host + '/media',
      media,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateMedia(
    token: string,
    media) {
    return this.http.put(this.host + '/media',
      media,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  deleteMedia(
    token: string,
    media) {
    const params = new HttpParams().set('id', media.id);
    return this.http.delete(this.host + '/media',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  readAbout(
    token: string,
  ) {
    const params = new HttpParams();
    return this.http.get<any>(this.host + '/about',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateAbout(
    token: string,
    about: string,
    aboutEn: string) {
    return this.http.put(this.host + '/about',
      {
        about,
        aboutEn
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  readContactsSales(
    token: string,
  ) {
    const params = new HttpParams();
    return this.http.get<any>(this.host + '/contacts-sales',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateContactsSales(
    token: string,
    info: string,
    infoEn: string) {
    return this.http.put(this.host + '/contacts-sales',
      {
        info,
        infoEn
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  readShopInfo(
    token: string,
  ) {
    const params = new HttpParams();
    return this.http.get<any>(this.host + '/shop-info',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateShopInfo(
    token: string,
    info: string,
    infoEn: string,
    infoPl: string) {
    return this.http.put(this.host + '/shop-info',
      {
        info,
        infoEn,
        infoPl
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }


  uploadFile(
    token: string,
    formData) {
    return this.http.post(this.host + '/upload-file',
      formData,
      {
        headers: new HttpHeaders({
          'token': token
        })
      }
    )
  }

  deleteFile(
    token: string,
    filename: string) {
    const params = new HttpParams().set('filename', filename);
    return this.http.delete(this.host + '/file',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  readHome(
    token: string,
  ) {
    const params = new HttpParams();
    return this.http.get<any>(this.host + '/home',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateHome(
    token: string,
    home: string,
    homeEn: string) {
    return this.http.put(this.host + '/home',
      {
        home,
        homeEn
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }

  searchExistedMedia(
    token: string,
    title: string,
    titleEn: string,
    url: string) {
    const params = new HttpParams().set('title', title).set('titleEn', titleEn).set('url', url);
    return this.http.get<Media>(this.host + '/search-existed-media',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  searchExistedPicture(
    token: string,
    category: number,
    title: string,
    titleEn: string,
    url: string) {
    const params = new HttpParams().set('category', category.toString()).set('title', title).set('titleEn', titleEn).set('url', url);
    return this.http.get<Picture>(this.host + '/search-existed-picture',
      {
        params,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    );
  }

  updateCategoryIndexes(
    token: string,
    ids) {
    return this.http.put(this.host + '/category-indexes',
      ids,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token
        })
      }
    )
  }
}
