import {Injectable} from '@angular/core';

@Injectable()
export class UtilsService {
  constructor() {
  }

  transliterate(ukrainian: string) {
    const letters = 'абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ';
    const alphabet = [
      {'uk': 'а', 'en': 'a'},
      {'uk': 'б', 'en': 'b'},
      {'uk': 'в', 'en': 'v'},
      {'uk': 'г', 'en': 'h'},
      {'uk': 'ґ', 'en': 'g'},
      {'uk': 'д', 'en': 'd'},
      {'uk': 'е', 'en': 'e'},
      {'uk': ' є', 'en': 'ye'},
      {'uk': 'є', 'en': 'ie'},
      {'uk': 'ж', 'en': 'zh'},
      {'uk': 'з', 'en': 'z'},
      {'uk': 'и', 'en': 'y'},
      {'uk': 'і', 'en': 'i'},
      {'uk': ' ї', 'en': 'yi'},
      {'uk': 'ї', 'en': 'i'},
      {'uk': ' й', 'en': 'y'},
      {'uk': 'й', 'en': 'i'},
      {'uk': 'к', 'en': 'k'},
      {'uk': 'л', 'en': 'l'},
      {'uk': 'м', 'en': 'm'},
      {'uk': 'н', 'en': 'n'},
      {'uk': 'о', 'en': 'o'},
      {'uk': 'п', 'en': 'p'},
      {'uk': 'р', 'en': 'r'},
      {'uk': 'с', 'en': 's'},
      {'uk': 'т', 'en': 't'},
      {'uk': 'у', 'en': 'u'},
      {'uk': 'ф', 'en': 'f'},
      {'uk': 'х', 'en': 'kh'},
      {'uk': 'ц', 'en': 'ts'},
      {'uk': 'ч', 'en': 'ch'},
      {'uk': 'ш', 'en': 'sh'},
      {'uk': 'щ', 'en': 'shch'},
      {'uk': ' ю', 'en': 'yu'},
      {'uk': 'ю', 'en': 'iu'},
      {'uk': ' я', 'en': 'ya'},
      {'uk': 'я', 'en': 'ia'},
      {'uk': 'ь', 'en': ''},
      {'uk': 'А', 'en': 'A'},
      {'uk': 'Б', 'en': 'B'},
      {'uk': 'В', 'en': 'V'},
      {'uk': 'Г', 'en': 'H'},
      {'uk': 'Ґ', 'en': 'G'},
      {'uk': 'Д', 'en': 'D'},
      {'uk': 'Е', 'en': 'E'},
      {'uk': ' Є', 'en': 'Ye'},
      {'uk': 'Є', 'en': 'Ie'},
      {'uk': 'Ж', 'en': 'Zh'},
      {'uk': 'З', 'en': 'Z'},
      {'uk': 'И', 'en': 'Y'},
      {'uk': 'І', 'en': 'I'},
      {'uk': ' ї', 'en': 'Yi'},
      {'uk': 'ї', 'en': 'I'},
      {'uk': ' Й', 'en': 'Y'},
      {'uk': 'Й', 'en': 'I'},
      {'uk': 'К', 'en': 'K'},
      {'uk': 'Л', 'en': 'L'},
      {'uk': 'М', 'en': 'M'},
      {'uk': 'Н', 'en': 'N'},
      {'uk': 'О', 'en': 'O'},
      {'uk': 'П', 'en': 'P'},
      {'uk': 'Р', 'en': 'R'},
      {'uk': 'С', 'en': 'S'},
      {'uk': 'Т', 'en': 'T'},
      {'uk': 'У', 'en': 'U'},
      {'uk': 'Ф', 'en': 'F'},
      {'uk': 'Х', 'en': 'Kh'},
      {'uk': 'Ц', 'en': 'Ts'},
      {'uk': 'Ч', 'en': 'Ch'},
      {'uk': 'Ш', 'en': 'Sh'},
      {'uk': 'Щ', 'en': 'Shch'},
      {'uk': ' Ю', 'en': 'Iu'},
      {'uk': 'Ю', 'en': 'Yu'},
      {'uk': ' Я', 'en': 'Ya'},
      {'uk': 'Я', 'en': 'Ia'},
      {'uk': 'Ь', 'en': ''}
    ];
    let english = '';
    ukrainian = ' ' + ukrainian;
    for (let i = 0; i < ukrainian.length; i++) {
      let found = false;
      for (let ch in alphabet) {
        if ((i + 1) < ukrainian.length && alphabet[ch]['uk'][1] === ukrainian[i + 1] && letters.indexOf(ukrainian[i]) === -1) {
          english = english + ' ' + alphabet[ch]['en'];
          i++;
          found = true;
          break;
        } else if (alphabet[ch]['uk'] === ukrainian[i]) {
          english = english + alphabet[ch]['en'];
          found = true;
          break;
        }
      }
      if (!found) {
        english = english + ukrainian[i];
      }
    }
    return english.trim();
  }

  stringToURL(str: string) {
    if (!str) {
      str = '';
    }
    return encodeURI(str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^0-9a-z\-]/gi, '')
    );
  }
}


