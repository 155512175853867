import {Component} from '@angular/core';
import {StorageService} from "./services/storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'shupliak-admin';

  constructor(public router: Router,
              public storage: StorageService) {
    if (!this.storage.get('token')) {
      this.router.navigate(['/login']);
    }
  }

  isLoading() {
    return this.storage.get('loading');
  }
}
