<mat-card *ngIf="mode==='list'">
  <mat-card-header>
    <h2>Галерея робіт</h2>
  </mat-card-header>
  <mat-card-content>
    <button mat-button (click)="open({})">Додати</button>
    <mat-card *ngFor="let item of pictures">
      <mat-card-header>
        <mat-card-title>{{item['title']}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="delete(item)">Видалити</button>
      </mat-card-actions>
    </mat-card>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="mode==='file'">
  <mat-card-header>
    <h2 *ngIf="!picture['id']">Додавання нового матеріалу</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Назва</mat-label>
      <input matInput type="text" [(ngModel)]="picture['title']" (keyup)="onTitleChanged()"
             (change)="doesPictureExist()"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>URL</mat-label>
      <input matInput type="text" [(ngModel)]="picture['url']" [disabled]="!picture['customUrl']"
             (change)="doesPictureExist()"/>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="picture['customUrl']" (change)="onCustomUrlChange()">Ввести URL вручну</mat-checkbox>
    <div class="existed-picture" *ngIf="existedPicture['id']">
      <p *ngIf="existedPictureError['sameTitle'] && existedPictureError['sameCategory']">
        Картина з такою назвою в цій категогії вже є. Ви точно хочете додати ще одну?
      </p>
      <img src="{{rest.websiteHost}}/pictures/{{existedPicture['picture']}}"/>
    </div>

    <div>
      <mat-label>Опис</mat-label>
      <angular-editor [placeholder]="'Опис'" [(ngModel)]="picture['description']"
                      [config]="editorConfig"></angular-editor>
    </div>

    <mat-form-field>
      <mat-card class="image-component">
        <mat-card-title>Зображення</mat-card-title>
        <mat-card-content>
          <img mat-card-image src="{{rest.websiteHost}}/pictures/{{picture['picture']}}"
               *ngIf="picture['picture']"/>
        </mat-card-content>
        <mat-card-actions>
          <input class="hidden" id="picture-file-input" type="file" (change)="uploadFile('picture')"/>
          <input class="hidden" matInput type="text" [(ngModel)]="pictureFile"/>
          <button mat-button color="primary" (click)="deleteFile('picture', picture['picture'])"
                  [disabled]="!picture['picture']">
            Видалити зображення
          </button>
          <button mat-button color="primary" (click)="chooseFile('picture')"
                  [disabled]="!!picture['picture'] || !picture['url'] || !picture['category']">
            Обрати зображення
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="picture['id']" (click)="delete(picture)">Видалити</button>
    <button mat-button *ngIf="!picture['id']" (click)="add(picture)"
            [disabled]="!(picture['title'] && picture['titleEn'] && picture['url'] || existedPictureError['sameUrl'])">
      Додати
    </button>
    <button mat-button (click)="cancel()">Відміна</button>
  </mat-card-actions>
</mat-card>
