<mat-card>
  <mat-card-header>
    Вхід
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput type="text" [(ngModel)]="email"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Пароль</mat-label>
      <input matInput type="password" [(ngModel)]="password"/>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="login()">Вхід</button>
  </mat-card-actions>
</mat-card>
