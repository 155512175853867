<mat-card *ngIf="mode==='list'">
  <mat-card-header>
    <h2>Публікації</h2>
  </mat-card-header>
  <mat-card-content>
    <button mat-button (click)="open({})">Додати</button>
    <mat-card *ngFor="let item of media">
      <mat-card-header>
        <mat-card-title>{{item['title']}} ({{item['date']}})</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="open(item)">Редагувати</button>
        <button mat-button (click)="delete(item)">Видалити</button>
      </mat-card-actions>
    </mat-card>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="mode==='media'">
  <mat-card-header>
    <h2 *ngIf="mediaItem['id']">Редагування {{mediaItem['title']}}</h2>
    <h2 *ngIf="!mediaItem['id']">Додавання нової публікації</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Назва</mat-label>
      <input matInput type="text" [(ngModel)]="mediaItem['title']" (keyup)="onTitleChanged()"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Назва Англійською</mat-label>
      <input matInput type="text" [(ngModel)]="mediaItem['titleEn']" (keyup)="onTitleEnChanged()"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>URL</mat-label>
      <input matInput type="text" [(ngModel)]="mediaItem['url']" [disabled]="!mediaItem['customUrl']"
             (keyup)="searchExistedMedia()"/>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="mediaItem['customUrl']" (change)="onCustomUrlChange()">Ввести URL вручну</mat-checkbox>
    <div class="existed" *ngIf="existedMedia['id']">
      <p
        *ngIf="existedMediaError['sameTitle']">
        Публікація з такою назвою вже є. Ви точно хочете додати ще одну?
      </p>
      <p
        *ngIf="existedMediaError['sameTitleEn']">
        Публікація з такою назвою англійською вже є. Ви точно хочете додати ще одну?
      </p>
      <p
        *ngIf="existedMediaError['sameUrl']">
        Виставка з такою адресою вже є. Неможливо додати ще одну
      </p>
      <a
        href="{{rest.host}}/media/{{existedMedia['url']}}">{{existedMedia['title']}}</a>
    </div>
    <mat-form-field>
      <mat-label>Дата публікації</mat-label>
      <input matInput type="date" [(ngModel)]="mediaItem['date']"/>
    </mat-form-field>
    <div>
      <mat-label>Опис</mat-label>
      <angular-editor [placeholder]="'Опис'" [(ngModel)]="mediaItem['description']"
                      [config]="editorConfig"></angular-editor>
    </div>
    <div>
      <mat-label>Опис Англійською</mat-label>
      <angular-editor [placeholder]="'Опис'" [(ngModel)]="mediaItem['descriptionEn']"
                      [config]="editorConfig"></angular-editor>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="mediaItem['id']" (click)="edit(mediaItem)"
            [disabled]="existedMediaError['sameUrl'] || !mediaItem['date']">
      Редагувати
    </button>
    <button mat-button *ngIf="mediaItem['id']" (click)="delete(mediaItem)">Видалити</button>
    <button mat-button *ngIf="!mediaItem['id']" (click)="add(mediaItem)"
            [disabled]="existedMediaError['sameUrl'] || !mediaItem['date']">
      Додати
    </button>
    <button mat-button (click)="cancel()">Відміна</button>
  </mat-card-actions>
</mat-card>
