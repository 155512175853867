import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  about: string;
  aboutEn: string;
  oldAbout: string;
  oldAboutEn: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false
  };

  constructor(public router: Router,
              private storage: StorageService,
              public rest: RESTService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.read();
    });
  }

  cancel() {
    this.about = this.oldAbout;
    this.aboutEn = this.oldAboutEn;
  }

  read() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readAbout(token).subscribe((data) => {
      this.about = data.about;
      this.aboutEn = data.aboutEn;
      this.oldAbout = this.about;
      this.oldAboutEn = this.aboutEn;
      this.storage.set('loading', false);
    });
  }

  update() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.updateAbout(token, this.about, this.aboutEn).subscribe((data) => {
      this.storage.set('loading', false);
      this.router.navigate(['/home']);
    });
  }
}
