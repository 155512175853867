import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Category} from "../../interfaces/category";
import {StorageService} from "../../services/storage.service";
import {RESTService} from "../../services/rest.service";
import {UtilsService} from "../../services/utils.service";
import {Picture} from "../../interfaces/picture";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  mode: string;
  pictures: Picture[];
  originalPictures: Picture[];
  categories: Category[];
  category: Category;

  constructor(public router: Router,
              private storage: StorageService,
              public rest: RESTService,
              private utils: UtilsService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.readCategories();
    });
  }

  readCategories() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readCategories(token).subscribe((data) => {
      this.categories = data;
      this.category = this.categories[0];
      this.storage.set('loading', false);
      this.readPictures();
    });
  }

  readPictures() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    this.rest.readPictures(token, this.category).subscribe((data) => {
      this.pictures = data;
      this.originalPictures = data;
      this.storage.set('loading', false);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pictures, event.previousIndex, event.currentIndex);
  }

  cancel() {
    this.pictures = this.originalPictures.map(a => {
      return {...a}
    });
  }

  save() {
    this.storage.set('loading', true);
    const token = this.storage.get('token');
    let ids = [];
    for (let picture of this.pictures) {
      ids.push(picture.id);
    }
    this.rest.updateCategoryIndexes(token, ids).subscribe((data) => {
      this.storage.set('loading', false);
    });
  }
}
