<mat-card *ngIf="mode==='list'">
  <mat-card-header>
    <h2>Галерея робіт</h2>
  </mat-card-header>
  <mat-card-content>
    <button mat-button (click)="open({})">Додати</button>
    <mat-form-field>
      <mat-label>Категорія</mat-label>
      <mat-select [(ngModel)]="category" (ngModelChange)="readPictures()">
        <mat-option *ngFor="let c of categories" [value]="c">
          {{c.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-card *ngFor="let item of pictures">
      <mat-card-header>
        <mat-card-title>{{item['title']}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="open(item)">Редагувати</button>
        <button mat-button (click)="delete(item)">Видалити</button>
      </mat-card-actions>
    </mat-card>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="mode==='picture'">
  <mat-card-header>
    <h2 *ngIf="picture['id']">Редагування {{picture['title']}}</h2>
    <h2 *ngIf="!picture['id']">Додавання нового матеріалу</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Назва</mat-label>
      <input matInput type="text" [(ngModel)]="picture['title']" (keyup)="onTitleChanged()"
             (change)="doesPictureExist()"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Назва Англійською</mat-label>
      <input matInput type="text" [(ngModel)]="picture['titleEn']" (keyup)="onTitleEnChanged()"
             (change)="doesPictureExist()"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>URL</mat-label>
      <input matInput type="text" [(ngModel)]="picture['url']" [disabled]="!picture['customUrl']"
             (change)="doesPictureExist()"/>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="picture['customUrl']" (change)="onCustomUrlChange()">Ввести URL вручну</mat-checkbox>
    <div class="existed-picture" *ngIf="existedPicture['id']">
      <p *ngIf="existedPictureError['sameTitle'] && existedPictureError['sameCategory']">
        Картина з такою назвою в цій категогії вже є. Ви точно хочете додати ще одну?
      </p>
      <p *ngIf="existedPictureError['sameTitle'] && !existedPictureError['sameCategory']">
        Картина з такою назвою вже є, але в категорії "{{existedPicture['categoryTitle']}}". Ви точно хочете додати цю?
      </p>
      <p *ngIf="existedPictureError['sameTitleEn'] && existedPictureError['sameCategory']">
        Картина з такою назвою англійською в цій категогії вже є. Ви точно хочете додати ще одну?
      </p>
      <p *ngIf="existedPictureError['sameTitle'] && !existedPictureError['sameCategory']">
        Картина з такою назвою англійською вже є, але в категорії "{{existedPicture['categoryTitle']}}".
        Ви точно хочете додати цю?
      </p>
      <p *ngIf="existedPictureError['sameUrl']">
        Картина з такою адресою в цій категогії вже є. Неможливо додати ще одну
      </p>
      <img src="{{rest.websiteHost}}/pictures/{{existedPicture['picture']}}"/>
    </div>
    <mat-form-field>
      <mat-label>Категорія</mat-label>
      <mat-select [(ngModel)]="picture['category']" (ngModelChange)="doesPictureExist()">
        <mat-option *ngFor="let c of categories" [value]="c.id">
          {{c.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Техніка</mat-label>
      <mat-select placeholder="Technique" multiple [(ngModel)]="picture['technique']">
        <mat-option *ngFor="let t of techniques" [value]="t.id">{{t.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Розділ</mat-label>
      <mat-select [(ngModel)]="picture['section']">
        <mat-option *ngFor="let s of sections" [value]="s.id">
          {{s.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Стиль</mat-label>
      <mat-select [(ngModel)]="picture['style']">
        <mat-option *ngFor="let s of styles" [value]="s.id">
          {{s.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Напрямок</mat-label>
      <mat-select [(ngModel)]="picture['movement']">
        <mat-option *ngFor="let m of movements" [value]="m.id">
          {{m.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Серія</mat-label>
      <mat-select multiple [(ngModel)]="picture['series']">
        <mat-option *ngFor="let s of series" [value]="s.id">
          {{s.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Рік</mat-label>
      <input matInput type="number" [(ngModel)]="picture['year']"/>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Дата</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="picture['date']">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Розмір</mat-label>
      <input matInput type="text" [(ngModel)]="picture['size']"/>
    </mat-form-field>
    <div>
      <mat-label>Опис</mat-label>
      <angular-editor [placeholder]="'Опис'" [(ngModel)]="picture['description']"
                      [config]="editorConfig"></angular-editor>
    </div>
    <div>
      <mat-label>Опис Англійською</mat-label>
      <angular-editor [placeholder]="'Опис'" [(ngModel)]="picture['descriptionEn']"
                      [config]="editorConfig"></angular-editor>
    </div>

    <mat-form-field>
      <mat-card class="image-component">
        <mat-card-title>Зображення</mat-card-title>
        <mat-card-content>
          <img mat-card-image src="{{rest.websiteHost}}/pictures/{{picture['picture']}}"
               *ngIf="picture['picture']"/>
        </mat-card-content>
        <mat-card-actions>
          <input class="hidden" id="picture-file-input" type="file" (change)="uploadFile('picture')"/>
          <input class="hidden" matInput type="text" [(ngModel)]="pictureFile"/>
          <button mat-button color="primary" (click)="deleteFile('picture', picture['picture'])"
                  [disabled]="!picture['picture']">
            Видалити зображення
          </button>
          <button mat-button color="primary" (click)="chooseFile('picture')"
                  [disabled]="!!picture['picture'] || !picture['url'] || !picture['category']">
            Обрати зображення
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Додаткові зображення</mat-label>
      <div class="additional-images-block">
        <div class="additional-image-block" *ngFor="let ai of picture['additionalImages']">
          <img mat-card-image src="{{rest.websiteHost}}/pictures/{{ai}}"/>
          <button mat-button color="primary" (click)="deleteFile('additionalImage', ai)">
            Видалити зображення
          </button>
        </div>
        <div class="add-additional-image-block">
          <input class="hidden" id="additionalImage-file-input" type="file" (change)="uploadFile('additionalImage')"/>
          <input class="hidden" matInput type="text" [(ngModel)]="additionalPictureFile"/>
          <button mat-button color="primary" (click)="chooseFile('additionalImage')"
                  [disabled]="!picture['url'] || !picture['category']">
            Додати зображення
          </button>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Ціна</mat-label>
      <input matInput type="text" [(ngModel)]="picture['price']"/>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="picture['id']" (click)="edit(picture)"
            [disabled]="!(picture['title'] && picture['titleEn'] && picture['url'] && picture['category'] && picture['section'] && picture['picture']) || existedPictureError['sameUrl']">
      Зберегти
    </button>
    <button mat-button *ngIf="picture['id']" (click)="delete(picture)">Видалити</button>
    <button mat-button *ngIf="!picture['id']" (click)="add(picture)"
            [disabled]="!(picture['title'] && picture['titleEn'] && picture['url'] && picture['category'] && picture['section'] && picture['picture']) || existedPictureError['sameUrl']">
      Додати
    </button>
    <button mat-button (click)="cancel()">Відміна</button>
  </mat-card-actions>
</mat-card>
