import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AboutComponent} from "./components/about/about.component";
import {CategoryComponent} from "./components/category/category.component";
import {ContactsSalesComponent} from "./components/contacts-sales/contacts-sales.component";
import {HomeComponent} from "./components/home/home.component";
import {MediaComponent} from "./components/media/media.component";
import {LoginComponent} from "./components/login/login.component";
import {PicturesComponent} from "./components/pictures/pictures.component";
import {ShopComponent} from "./components/shop/shop.component";

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'about', component: AboutComponent},
  {path: 'category', component: CategoryComponent},
  {path: 'contacts-sales', component: ContactsSalesComponent},
  {path: 'shop', component: ShopComponent},
  {path: 'home', component: HomeComponent},
  {path: 'media', component: MediaComponent},
  {path: 'pictures', component: PicturesComponent},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'login', pathMatch: 'full'}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
